$(document).ready(function() {

  /**********************************************
    NAVBAR
   **********************************************/
  $(window).scroll(function(){
    var scrollTop = 103;
    if($(window).scrollTop() >= scrollTop){
      $('.nm-header, .nav-wrapper').addClass('sticky')
    }
    if($(window).scrollTop() < scrollTop){
      $('.nm-header, .nav-wrapper').removeClass('sticky');	
    }
  })

  /**********************************************
    SWIPER SLIDER
   **********************************************/
  var swiper = new Swiper('.swiper-container', {
    speed: 2000,
    effect: 'fade',
    loop: true,
    autoplay: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  /**********************************************
    PASSWORD
   **********************************************/

  /** TOOGLE PASSWORD */
  const password = document.querySelector('#password');
  const toggle = document.querySelector('#toggle-password');
  // LOST PASSWORD
  const forgotPassword = document.querySelector('#forgot_password');
  const forgotToggle = document.querySelector('#toggle-forgot_password');
  // CONFIRM PASSWORD
  const confirmForgotPassword = document.querySelector('#confirm_password');
  const confirmForgotToggle = document.querySelector('#toggle-confirm_password');

  if(window.location.href.indexOf('registro') > -1 || window.location.href.indexOf('iniciar-sesion') > -1 ) {
    toggle.addEventListener('click', () => {
      if (password.type == "password") {
        password.type = "text";
      } else {
        password.type = "password";
      }
    });
  }
  if ( window.location.href.indexOf('mi-cuenta') > -1 ) {
    toggle.addEventListener('click', () => {
      if (password.type == "password") {
        password.type = "text";
      } else {
        password.type = "password";
      }
    });
  }
  if ( window.location.href.indexOf('restaura-contrasena') > -1 ) {
    forgotToggle.addEventListener('click', () => {
      if (forgotPassword.type == "password") {
        forgotPassword.type = "text";
      } else {
        forgotPassword.type = "password";
      }
    });
    
    confirmForgotToggle.addEventListener('click', () => {
      if (confirmForgotPassword.type == "password") {
        confirmForgotPassword.type = "text";
      } else {
        confirmForgotPassword.type = "password";
      }
    });
  }

  /**********************************************
    CONFIRMO SI LAS CONTRASEÑAS COINCIDEN
   **********************************************/
  const fpassword = $('#forgot_password');
  const fcpassword = $('#confirm_password');

  fcpassword.on('blur', function(){
    fp = fpassword.val();
    fc = $(this).val();
    if (fc != fp) {
      fcpassword.val("");
      Toast.fire({
        icon: 'error',
        title: 'Las contraseñas no coinciden.',
      });
    }
  });

  /**********************************************
    INPUT RANGE
   **********************************************/
  const allRanges = document.querySelectorAll(".range-wrapper");
  allRanges.forEach(wrap => {
    const range = wrap.querySelector(".range");
    const tooltip = wrap.querySelector(".range-tooltip");
    
    range.addEventListener("input", () => {
      tooltip.style.opacity = 1;
      tooltip.style.visibility = "visible";
      setTooltip(range, tooltip);
    });
    setTooltip(range, tooltip);
  });

  function setTooltip(range, tooltip) {
    const val = range.value;
    const min = range.min ? range.min : 0;
    const max = range.max ? range.max : 100;
    const newVal = Number(((val - min) * 100) / (max - min));
    tooltip.innerHTML = val;

    // Sorta magic numbers based on size of the native UI thumb
    tooltip.style.left = `calc(${newVal}% + (${-5 - newVal * 0.15}px))`;
  }

  /**********************************************
    MAP
   **********************************************/
  if (window.location.href.indexOf('contacto') > 1) {
    var mymap = L.map('map', {
      center: [-33.449377, -70.668139],
      zoom: 12,
      scrollWheelZoom: false
    });
    var nanamiaIcon = L.icon({
      iconUrl: '../../dist/images/sign-logo.png',
      iconSize:     [45, 36], // size of the icon
      iconAnchor:   [45, 36], // point of the icon which will correspond to marker's location
      popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });
    var marker = L.marker([-33.449377, -70.668139],{ icon: nanamiaIcon }).addTo(mymap);
    L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(mymap);
  }

  /**********************************************
    INIT TABS
   **********************************************/
  $('#account-tabs a').on('click', function (e) {
    e.preventDefault()
    $(this).tab('show')
  })

  /**********************************************
    RUT VALIDATE
   **********************************************/
  $("input[name='rut']").Rut({
    on_error: function() { 
      Toast.fire({
        icon: 'error',
        title: 'El Rut ingresado es incorrecto.',
      });
    },
    format_on: 'change'
  });
  
  /**********************************************
    AJAX CONTENT GRID LOAD
   **********************************************/
    filter_data(1);

    function filter_data(page, query = '') {
      var action = 'fetch_data';
      var minimum_age = $('#hidden_minimum_age').val();
      var maximum_age = $('#hidden_maximum_age').val();
      var disposicion = get_filter('disposicion');
      var nacionalidad = get_filter('nacionalidad');
      var ciudad = get_filter('ciudad');
      var page = page;
      $.ajax({
        url: "inc/fetch_data.php",
        method: "POST",
        data: {
          action: action,
          disposicion: disposicion,
          nacionalidad: nacionalidad,
          ciudad: ciudad,
          minimum_age: minimum_age,
          maximum_age: maximum_age,
          page: page
        },
        beforeSend: function() {
          $('#staff-loading').show();
        },
        success: function(data) {
          var jsonData = JSON.parse(data);
          $('#staff-grid').html(jsonData.nanas);
          $('#totalPages').val(jsonData.totalpaginas);
          $('#PagesActual').val(jsonData.PagesActual);
          pageshtml(jsonData.totalpaginas, jsonData.PagesActual);
          setInterval(function() {
            $('#staff-loading').hide();
          }, 1500);
        },
      });
    }

    function get_filter(id_name) {
      var filter = $('#' + id_name).val();
      return filter;
    }

    $('.filter_selector').on('change', function() {
      filter_data(1);
    });

    $('#age_range').slider({
      range: true,
      min: 18,
      max: 70,
      values: [18, 70],
      step: 1,
      stop: function(event, ui) {
        $('#age_show').html(ui.values[0] + ' - ' + ui.values[1]);
        $('#hidden_minimum_age').val(ui.values[0]);
        $('#hidden_maximum_age').val(ui.values[1]);
        filter_data();
      }
    });

    function pageshtml(totalPages, PagesActual) {
      //$('#pagination').pagination('updateItemsOnPage', 20);
      $('#pagination').pagination({
        items: totalPages,
        displayedPages: 5,
        edges: 1,
        currentPage: PagesActual,
        cssStyle: 'nm-pagination',
        prevText: '<img src="./dist/images/prev.svg" alt="Previous">',
        nextText: '<img src="./dist/images/next.svg" alt="Next">',
  
        onPageClick: function(pageNumber) {
          filter_data(pageNumber);
          $('html, body').animate({
            scrollTop: $("#staff-wrapper").offset().top -100
          }, 1000);
        }
      });
    }

    /**********************************************
    AJAX CONTENT ITEM LOAD
   **********************************************/
    function content_data(id) {
      var id = id;
      $.ajax({
        url: "inc/content_data.php",
        method: "GET",
        data: {
          id: id,
        },
        success: function(data) {
          $('.staff-content').html(data);
        },
      });
    }

    var gridWrapper = $('.staff-wrapper'),
        gridItemContent = $('.staff-content'),
        body = $('body');

    // Open grid content panel
    gridWrapper.on('click', '.staff-grid li a', function(){
      var idItem = $(this).attr('data-id');
      content_data(idItem);
      gridItemContent.addClass('is-visible');
      body.addClass('content-is-visible');
    });

    //close gri item content
    gridItemContent.on('click', '.staff-close', function(event){
      event.preventDefault();
      gridItemContent.removeClass('is-visible');
      body.removeClass('content-is-visible');
    });

});


  