jQuery(document).ready(function($){
	//if you change this breakpoint in the style.css file (or _layout.scss if you use SASS), don't forget to update this value as well
	var MqL = 991;
	//move nav element position according to window width
	moveNavigation();
	$(window).on('resize', function(){
		(!window.requestAnimationFrame) ? setTimeout(moveNavigation, 300) : window.requestAnimationFrame(moveNavigation);
	});

	//mobile - open lateral menu clicking on the menu icon
	$('.nav-trigger').on('click', function(event){
		event.preventDefault();
		if( $('.global-container').hasClass('nav-is-visible') ) {
			closeNav();
			$('.nm-overlay').removeClass('is-visible');
		} else {
			$(this).addClass('nav-is-visible');
			$('.nm-header').addClass('nav-is-visible');
			$('.navbar').addClass('nav-is-visible');
			$('.navbar-nav').addClass('nav-is-visible');
			$('.global-container').addClass('nav-is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
				// $('body').addClass('overflow-hidden');
			});
			$('.nm-overlay').addClass('is-visible');
			$('.nm-footer').addClass('nav-is-visible');
		}
	});

	$('.nm-overlay').on('click', function(){
		closeNav();
		$(this).removeClass('is-visible');
	});

	function closeNav() {
		$('.nm-header').removeClass('nav-is-visible');
		$('.nav-trigger').removeClass('nav-is-visible');
		$('.navbar').removeClass('nav-is-visible');
		$('.navbar-nav').removeClass('nav-is-visible');
		$('.global-container').removeClass('nav-is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
      $('body').removeClass('overflow-hidden');
		});
    $('.nm-footer').removeClass('nav-is-visible');
	}

	function checkWindowWidth() {
		//check window width (scrollbar included)
		var e = window, 
        a = 'inner';
        if (!('innerWidth' in window )) {
            a = 'client';
            e = document.documentElement || document.body;
        }
        if ( e[ a+'Width' ] >= MqL ) {
          return true;
        } else {
          return false;
        }
	}

	function moveNavigation(){
		var navigation = $('.navbar');
		var sociales = $('#sociales');
    var desktop = checkWindowWidth();

    if ( desktop ) {
			sociales.detach();
			sociales.insertAfter('.top-info');
			navigation.detach();
			navigation.appendTo('.nav-wrapper .container');
		} else {
			sociales.detach();
			sociales.insertAfter('.navbar .navbar-nav');
			navigation.detach();
			navigation.insertAfter('.global-container');
    }
    
	}
});